body {
  margin: 0;
  background-color: #ffffff;
}

@font-face {
  font-family: 'Prompt';
  src: url('fonts/Prompt-Regular.ttf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lexend Tera';
  src: url('fonts/LexendTera-Regular.ttf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
